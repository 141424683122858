/* ==========|  Variables  |========== */

:root {
  --animation-duration: 0.25s;
  --animation-duration-long: 0.65s;
}

/* ==========|  Classes  |========== */

.slide-up {
  animation: var(--animation-duration) slide-up-small 0s ease-out both;
}

.slide-up-bounce {
  animation: var(--animation-duration) slide-up-bounce 0s ease-out both;
}

.floating-action-buttons {
  animation: var(--animation-duration-long) floating-action-buttons 0s ease-in
    both;
}

/* ==========|  Animations  |========== */

@keyframes slide-up-small {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes slide-up-bounce {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  75% {
    transform: translateY(-5px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes floating-action-buttons {
  0% {
    height: 0px;
    width: 0px;
    padding: 0;
    border-radius: 72px;
  }
  10% {
    width: 72px;
    height: 72px;
    padding: 12px;
    border-radius: 72px;
  }
  30% {
    width: 76px;
    height: 76px;
    border-radius: 76px;
  }
  50% {
    width: 72px;
    height: 72px;
    border-radius: 72px;
  }
  70% {
    width: 282px;
    height: 72px;
    border-radius: 12px;
  }
  100% {
    width: 278px;
    height: 72px;
    border-radius: 12px;
  }
}
