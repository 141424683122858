

.custom-select {
  width: 100%;
  margin-top: 20px;
}

.project-status {
  font-size: 12px;
  color: white;
  border-radius: 15px;
  padding: 3px 12px;
}

.project-status.active {
  background-color: rgb(234, 252, 218);
  color: rgb(2, 102, 2);
}

.project-status.inactive {
  background-color: rgb(252, 218, 218);
  color: rgb(128, 0, 0);
}

.project-status.completed {
  background-color: rgb(218, 238, 252);
  color: rgb(2, 70, 102);
}

.project-status.onpause {
  background-color: rgb(248, 239, 198);
  color: rgb(128, 94, 0);
}

.project-status.pending {
  background-color: rgb(236, 218, 252);
  color: rgb(65, 2, 102);
}


.datepicker {
  border: none;
  border-bottom: 1px solid black;
  margin: 0px 0px 0px 5px;
  display: flex;

  flex-direction: row;
  z-index: 31;
}
.datePopper {
  z-index: 100;
}

/* Details Page */

.projectDetailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.detailsCard {
  display: flex;
  flex-direction: column;
  width: 30%;
  /* border: 2px solid red; */
  margin-bottom: 30px;
}
.detailsContainer {
  display: flex;
  flex-direction: row;
  padding: 30px 30px 20px 30px;
  justify-content: space-between;
  align-items: center;
}
.detailsHeading {
  font-size: 21;
  margin-bottom: 0;
  margin-top: 0;
  color: gray;
}

.detailsInfo {
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0px;
}

.project-btn-reg {
  background-color: #f04e30;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
}

/* Members */

.members-text {
  font-size: 16px;
  margin-bottom: 40px;
  font-weight: bold;
}

.multiple-select-dropdown {
  border: 2px solid black;
  width: 100%;
  margin-left: 20px;
  border-radius: 5px;
}

.multiple-select-option {
  padding: 2;
  margin: 3;
}

.project-members-ul {
  list-style-type: circle;
  /* border: 2px solid #f04e30; */
  padding: 10px;
}
.project-member-li {
  margin: 5px;
}

.space-bw-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/*  Popup */

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 30px;
  left: 0;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh);

  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

@media (max-width: 868px) {
  .cards {
    grid-template-columns: 1fr;
    gap: 24px;
    padding: 0px;
  }
}
