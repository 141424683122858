.main-body {
  margin-bottom: 65px !important;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.leave-container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 30px 0px 30px;
  min-width: 200px;
}

.leave-requests-map {
  margin: 50px;
  margin-bottom: 00px;
}
.leave-label {
  font-size: 16px;
}
.table-entries {
  font-size: 14px;
}
.leave-card {
  background-color: #fc7753;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px;

  margin-bottom: 20px;
  width: calc(33.33% - 20px);
  aspect-ratio: 1.5/0.3;
}

.container2 {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
.dashboard-row {
  display: flex;
  flex-direction: row;
}
.leave-card {
  background-color: #fc7753;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 auto 10px;
  width: 50%;
}
.link-category {
  text-decoration: none;
}
.category {
  font-size: 16px;
  color: white;
  margin: 0;
}
@media (max-width: 768px) {
  .main-body {
    padding: 0;
  }

  .container2 {
    margin-top: 0;
    flex-wrap: wrap;
  }
  .leave-card {
    width: 50%;
  }
}

.leave-button-container {
  justify-content: space-evenly;
  margin-bottom: 100px;
}
.leave-button-container button {
  padding: 10px 20px;
  background-color: #fc7753;
  margin: 5px 30px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.review-container {
  width: 75%;
  margin: 0 auto 10px;
}

.leave-list {
  list-style: none;
  padding: 0;
}

.table-heading {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  background-color: #f2f2f2;
  font-weight: bold;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.leave-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

.leave-row:hover {
  background-color: #f9f9f9;
}

.leave-row div {
  padding: 10px;
}

.leave-row div:first-child {
  font-weight: bold;
}

.leave-row + .leave-row {
  margin-top: 5px;
}

/* Leaves detail */

.leave-details-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.leave-details-container h2 {
  margin-top: 0;
}

.leave-info {
  margin-top: 20px;
}

.leave-info p {
  margin-bottom: 10px;
  text-align: left;
}

.leave-info strong {
  font-weight: bold;
  text-align: left;
}

.leave-info p:last-child {
  margin-bottom: 0;
}

.radio-buttons-options {
  display: flex;
  flex-direction: row;
  padding: 15px 0px 20px 0px;
}

.radio-buttons-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 25px 0px 20px 0px;
}

.add-leave-btn {
  background-color: #fc7753;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  width: 150px;
  margin-left: 20px;
  margin-top: 20px;
}

.radio-label {
  font-size: 16;
  font-weight: 500;
  margin-bottom: 0;
}
