body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Outfit';
  font-weight: 300;
  src: url(./Assets/fonts/Outfit-Light.ttf);
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url(./Assets/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url(./Assets/fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url(./Assets/fonts/Inter-SemiBold.ttf);
}

::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(101, 101, 101);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: darkgrey;
}

.Toastify__toast {
  -webkit-backdrop-filter: saturate(180%) blur(15px);
  backdrop-filter: saturate(180%) blur(15px);
  background-color: rgba(255, 255, 255, 0.8) !important;
  border-radius: 12px !important;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px !important;
  border: 0.5px solid lightgray;
}

.Toastify__toast-body {
  padding: 18px !important;
  text-align: left;
}

::selection {
  background: #7f3ffd40;
}

.css-1w11438-MuiTypography-root,
.css-vfkcqi-MuiTypography-root {
  display: none;
}

.secondary-font {
  font-family: 'Outfit', sans-serif !important;
}
